import { Role } from "@mmc/conferencing-booking-client";
import { Box, Button, Callout, CalloutText, Flex, HelpText, Text } from "@twilio-paste/core";
import { FC, FormEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { appStateTypes } from "../../../core/appReducer";
import countries from "../../../countries.json";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { useConferenceControlContext } from "../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useBookingContext } from "../../../hooks/useBookingContext/useBookingContext";
import { useToasterContext } from "../../../hooks/useToasterContext/useToasterContext";

interface CallMeTabType {
    connect: (webrtc?: boolean | undefined) => void,
    state: appStateTypes,
	isLoadingConferenceDetails: boolean
}

const localizations = {
	fr: countries.countries.fr,
	de: countries.countries.de,
	it: countries.countries.it,
	es: countries.countries.es,
	sv: countries.countries.sv
} as any;

export const CallMeTab: FC<CallMeTabType> = ({ connect, state, isLoadingConferenceDetails }: CallMeTabType) => {
	const { t, i18n } = useTranslation();
	const { dialOut } = useConferenceControlContext();
	const { toaster } = useToasterContext();
	const [callMeNumber, setCallMeNumber] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const { appState } = useAppState();
	const { telephonyPin } = useBookingContext();


	const shouldDisableButton = () => {
		// If we'll be starting the room (as opposed to joining) we need to wait for conference details
		// to finish loading. For now we're just checking if the user's role is a host or admin (these are
		// the only roles that can start the room) but we should also check if the room has already been started.

		switch (state.participantType) {
		case Role.Admin:
		case Role.Host:
			// For admins and hosts, disable the button if the conference has not finished loading
			return isLoadingConferenceDetails;

		default:
			return false;
		}
	};

	const outboundDial = async (e: FormEvent<EventTarget>) => {
		e.preventDefault();
		setError(false);
		setLoading(true);
		try {
			await dialOut(`+${callMeNumber}`);
			toaster.push({
				message: t("conference-ui.toasters.attempting-to-call"),
				variant: "neutral",
				dismissAfter: 10000
			});
			setLoading(false);
		} catch (error: any) {
			setError(true);
			toaster.push({
				message: (error.body && error.body.message) || t("conference-ui.toasters.unable-to-call"),
				variant: "error",
				dismissAfter: 10000
			});
			setLoading(false);
		}
	};

	const onChange = (value: string) => {
		setCallMeNumber(value);
		setError(false);
	};

	const hasPin = Boolean(appState.participantPin || telephonyPin?.pin);

	return (<>
		<Box display="flex" flex={1} flexDirection={["column", "row", "row"]} columnGap="space60" justifyContent="space-between" alignContent="space-between">
			<Box width="100%">
				<Text as="p" fontWeight={"fontWeightSemibold"} marginBottom="space40">{t("choose-your-adventure-page.tab3.sub-title")}</Text>
				<form onSubmit={outboundDial}>
					<Box marginBottom="space40">
						<PhoneInput
							localization={localizations[i18n.language]}
							key={i18n.language}
							country={"us"}
							value={callMeNumber}
							excludeCountries={["cn"]}
							onChange={onChange}
							enableSearch
							inputProps={{
								name: "Phone Number",
								autoFocus: true,
								label: "Phone Number"
							}}
							inputStyle={{
								borderRadius: "4px",
								color: "#121c2d",
								borderColor: "#8891aa",
								paddingTop: "12px",
								paddingBottom: "12px"
							}}
							searchStyle={{
								borderRadius: "4px",
								color: "#121c2d",
								borderColor: "#8891aa",
								width:"92%",
								padding: "10px 10px"
							}}
						/>
						{error && <HelpText id="numberId" variant="error">{t("choose-your-adventure-page.tab3.call-me-error")}</HelpText>}
						{state.participantType !== Role.Viewer &&
							<Callout marginY="space60" variant='neutral'>
								<CalloutText><Trans i18nKey="choose-your-adventure-page.tab3.info-alert">If you would like to use the &quot;Call Me&quot; feature, please enter your number and click the &quot;Call Me&quot; button first, before joining the conference.</Trans></CalloutText>
							</Callout>
						}
					</Box>
					<Flex vAlignContent="center" hAlignContent="between" >

						<Button variant="primary" type="submit" loading={loading} disabled={!hasPin}>{t("choose-your-adventure-page.tab3.title")}</Button>

						{state.participantType !== Role.Viewer && <Box display="flex" flexDirection="row" alignContent="end" justifyContent="end">
							<Button variant="primary" data-cy-join-now onClick={() => connect(false)} disabled={shouldDisableButton()}>
								{isLoadingConferenceDetails ?
									t("choose-your-adventure-page.please-wait") :
									state.participantType === Role.Admin || state.participantType === Role.Host ? t("choose-your-adventure-page.create-event-button") : t("choose-your-adventure-page.join-conference")
								}
							</Button>
						</Box>}
					</Flex>
				</form>
			</Box>
		</Box>
	</>);
}
