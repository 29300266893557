import { createContext, FC, useCallback, useState } from "react";
import { ReactElementProps } from "../../types";
import { ConferencePlaybackContextType } from "../../types/ciptex-sdk";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { ConferencePlaybackBotClient } from "../../clients/conferencePlaybackBotClient";
import { Configuration } from "@mmc/conferencing-playback-bot-client";
import { CONFERENCE_PLAYBACK_BOT_API_URL } from "../../constants";
import { Role } from "@mmc/conferencing-booking-client";

export const ConferencePlaybackContext = createContext<ConferencePlaybackContextType>(null!);

export const ConferencePlaybackProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [conferencePlaybackBotClient, setConferencePlaybackBotClient] = useState<ConferencePlaybackBotClient>();
	const { appState } = useAppState();
	const [ playState, setPlayState ] = useState<"playing" | "paused" | "stopped">("stopped")

	const connect = useCallback(
		(token: string) => {
			try {
				const client = new ConferencePlaybackBotClient(new Configuration({
					apiKey: `Bearer ${token}`,
					basePath: CONFERENCE_PLAYBACK_BOT_API_URL
				}));
				setConferencePlaybackBotClient(client);

				const { conferenceId, participantType } = appState;

				const isEligibleRole = (role: Role): boolean => {
					return role === Role.Admin || role === Role.Host;
				};

				if (conferenceId && participantType && isEligibleRole(participantType)) {
					client.audio.getApplicationCookie({ conferenceId: conferenceId });
				}
			} catch (error: any) {
				console.error("[CIPTEX]", error)
			}
		}, [appState]);

	const playUrl = async (url: string, loop = false) => {
		await conferencePlaybackBotClient?.audio.play({
			url: url,
			conferenceId: appState.conferenceId,
			accountSid: appState.accountSid,
			loop: loop,
			cID: appState.conferenceId
		});

		setPlayState("playing");
	};

	const stop = async () => {
		await conferencePlaybackBotClient?.audio.stop({
			conferenceId: appState.conferenceId,
			cID: appState.conferenceId
		});

		setPlayState("stopped");
	}

	const toggle = async () => {
		await conferencePlaybackBotClient?.audio.toggle({
			conferenceId: appState.conferenceId,
			cID: appState.conferenceId
		});

		setPlayState(playState === "playing" ? "paused" : "playing");
	}

	return <ConferencePlaybackContext.Provider value={{ connect, playUrl, stop, toggle  }}>{children}</ConferencePlaybackContext.Provider>;
}
