import { createContext, FC, useCallback, useState } from "react";
import { ReactElementProps } from "../../types";
import { ConferenceControlContextType } from "../../types/ciptex-sdk";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { CONFERENCE_CONTROL_API_URL, ENABLE_VIDEO, IDENTITY_SEPERATOR } from "../../constants";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { ConferenceControlClient } from "../../clients/conferenceControlClient";
import { CiptexParticipantUpdateStatusEnum, CiptexRoom, CiptexRoomUpdated, Configuration } from "@mmc/conferencing-conference-control-client";
import { CiptexViewerUpdatePlayerStatusEnum } from "@mmc/conferencing-conference-control-client/src/models/CiptexViewerUpdate";


export const ConferenceControlContext = createContext<ConferenceControlContextType>(null!);

export const ConferenceControlProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [conferenceControlClient, setConferenceControlClient] = useState<ConferenceControlClient>();
	const [playbackTimer, setPlaybackTimer] = useState<any>(0);
	const [isPlaybackBotPlaying, setIsPlaybackBotPlaying] = useState<boolean>(false);
	const { conference } = useBookingContext();
	const { appState } = useAppState();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_ciptex, setCiptex] = useState<CiptexRoom>();
	const [pendingPriorityRequest, setPendingPriorityRequest] = useState<Promise<any>|null>(null);

	const connect = useCallback(
		(ciptexRoom: CiptexRoom) => {
			try {
				const client = new ConferenceControlClient(new Configuration( {
					apiKey: `Bearer ${ciptexRoom.token}`,
					basePath: CONFERENCE_CONTROL_API_URL
				}));
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				window.conferenceControlClient = client;
				setConferenceControlClient(client);
				setCiptex(ciptexRoom);
			} catch (error: any) {
				console.error(error)
			}
		}, []);

	const raiseHand = async (raiseHandParam: boolean): Promise<void> => {
		await conferenceControlClient?.participant.updateParticipant({
			roomId: appState.conferenceId,
			ciptexParticipantUpdate: {
				userIdentity: appState.participantIdentity,
				raiseHand: raiseHandParam
			}
		});
	};

	const speakerInvite = async (userIdentity: string, speakerInviteParam: boolean): Promise<void> => {
		if (pendingPriorityRequest) {
			console.log("speakerInvite: Waiting for pending priority request to complete");

			// We don't care whether the priority request succeeded or failed, just that it's settled
			// so we can continue with our request without running concurrently.
			try {
				await pendingPriorityRequest;
			// eslint-disable-next-line no-empty
			} catch (err) {}

			console.log("speakerInvite: Resuming speaker invite request");
		}

		console.log(`speakerInvite: Sending request (${userIdentity} - ${speakerInviteParam})`);

		await conferenceControlClient?.participant.updateParticipant({
			roomId: appState.conferenceId,
			ciptexParticipantUpdate: {
				userIdentity: userIdentity,
				speakerInvite: speakerInviteParam
			}
		});
	};

	const updatePriority = async (userIdentity: string, priority: number): Promise<void> => {
		/**
		 * To work around an edge case where if a request to update priority is sent at roughly the same time
		 * as a request to bring the user to the stage, the user can end up in both viewers map and speakers map.
		 *
		 * Until we fix the problem on the API side (or on the Twilio Sync side), this is a UI workaround to ensure
		 * we don't concurrently send requests for priority and bring to stage. We instead store a reference to ongoing
		 * update priority requests, and queue bring to stage requests to begin after that has completed.
		 */
		const updatePriorityRequest = (async () => {
			await conferenceControlClient?.participant.updateParticipant({
				roomId: appState.conferenceId,
				ciptexParticipantUpdate: {
					userIdentity: userIdentity,
					priority: priority
				}
			});

			await new Promise(resolve => setTimeout(resolve, 2000));
		})();

		setPendingPriorityRequest(updatePriorityRequest);

		await updatePriorityRequest;

		setPendingPriorityRequest(null);
	};

	const toggleStream = async (stream: boolean): Promise<CiptexRoomUpdated> => {
		if (!conferenceControlClient) {
			throw new Error("No Conference Control Client");
		}

		return conferenceControlClient.room.updateRoom({
			roomId: appState.conferenceId,
			ciptexRoomUpdate: { stream }
		});
	};

	const toggleOpen = async (open: boolean): Promise<CiptexRoomUpdated> => {
		if (!conferenceControlClient) {
			throw new Error("No Conference Control Client");
		}

		return conferenceControlClient.room.updateRoom({
			roomId: appState.conferenceId,
			ciptexRoomUpdate: { open }
		});
	};

	const endEvent = async (): Promise<void> => {
		await conferenceControlClient?.room.removeRoom({ roomId: appState.conferenceId });
	};

	const removeSpeaker = async (userIdentity: string): Promise<void> => {
		await conferenceControlClient?.participant.updateParticipant({
			roomId: appState.conferenceId,
			ciptexParticipantUpdate: {
				userIdentity: userIdentity,
				status: CiptexParticipantUpdateStatusEnum.Disconnected
			}
		});
	};

	const viewerUpdate = async (playerStatus: CiptexViewerUpdatePlayerStatusEnum) => {
		conferenceControlClient?.participant.viewerUpdate({
			roomId: appState.conferenceId,
			ciptexViewerUpdate: {
				userIdentity: appState.participantIdentity,
				playerStatus: playerStatus
			}
		});
	};

	const switchToViewer = useCallback(async (): Promise<CiptexRoom> => {
		if (!window.conferenceControlClient) {
			throw new Error("No Authenticated Client");
		}

		const ciptexRoom: CiptexRoom = await window.conferenceControlClient?.participant.joinAsViewer({
			roomId: appState.conferenceId,
			ciptexRoomJoin: {
				userIdentity: appState.participantIdentity
			}
		});

		connect(ciptexRoom);
		return ciptexRoom;
	}, [appState.participantIdentity, appState.conferenceId, connect]);

	const joinStreamAsSpeaker = useCallback(async (oldToken: string, upgrade?: boolean): Promise<CiptexRoom> => {
		const client = new ConferenceControlClient(new Configuration( {
			apiKey: `Bearer ${oldToken}`,
			basePath: CONFERENCE_CONTROL_API_URL
		}));

		const ciptexRoom: CiptexRoom = await client.participant.joinAsSpeaker({
			roomId: appState.conferenceId,
			ciptexRoomJoin: {
				userIdentity: `${appState.participantId}${IDENTITY_SEPERATOR}${appState.participantIdentity}`
			},
			upgrade: upgrade
		});

		connect(ciptexRoom);
		return ciptexRoom;
	}, [appState.conferenceId, appState.participantId, appState.participantIdentity, connect]);

	const startRoom = useCallback(async (oldToken: string): Promise<CiptexRoom> => {
		try {
			const client = new ConferenceControlClient(new Configuration( {
				apiKey: `Bearer ${oldToken}`,
				basePath: CONFERENCE_CONTROL_API_URL
			}));

			// If the room already exists, join as a speaker. Otherwise create the room.
			// We still wrap in a try-catch to deal with the edge case of multiple hosts
			// trying to start at roughly the same time, in which case one will succeed, one
			// will fail, and the failing host will then try to join as a speaker instead.

			let roomAlreadyExists = false;

			if (appState.conferenceId) {
				try {
					const { status } = await client.room.fetchRoomStatus({ roomId: appState.conferenceId });

					if (status === "lobby" || status === "in-progress") {
						roomAlreadyExists = true;
					}
				} catch (_err) {
					// Failing to lookup the room probably means it just doesn't exist.
					// We can ignore this and just create the room as normal.
				}
			}

			if (roomAlreadyExists) {
				// Join as a speaker instead of trying to create the room
				return await joinStreamAsSpeaker(oldToken);
			}

			const ciptexRoom: CiptexRoom = await client.room.createRoom({
				ciptexRoomStart: {
					userIdentity: `${appState.participantId}${IDENTITY_SEPERATOR}${appState.participantIdentity}`,
					conferenceId: appState.conferenceId,
					video: ENABLE_VIDEO,
					stream: false,
					mediaRegion: conference?.mediaRegion
				}
			});

			connect(ciptexRoom);
			return ciptexRoom;
		} catch (error: any) {
			const roomAlreadyExistsError = 53113;

			if (error.body.code === roomAlreadyExistsError) {
				return await joinStreamAsSpeaker(oldToken);
			}

			throw error;
		}
	}, [conference, appState.conferenceId, appState.participantId, appState.participantIdentity, connect, joinStreamAsSpeaker]);

	const joinStreamAsViewer = useCallback(async (oldToken: string): Promise<CiptexRoom> => {
		const client = new ConferenceControlClient(new Configuration( {
			apiKey: `Bearer ${oldToken}`,
			basePath: CONFERENCE_CONTROL_API_URL
		}));

		const ciptexRoom: CiptexRoom = await client.participant.joinAsViewer({
			roomId: appState.conferenceId,
			ciptexRoomJoin: {
				userIdentity: appState.participantIdentity
			}
		});

		connect(ciptexRoom);
		return ciptexRoom;
	}, [appState.conferenceId, appState.participantIdentity, connect]);

	const dialOut = async (phoneNumber: string) => {
		if (appState.verifyToken) {
			const client = new ConferenceControlClient(new Configuration( {
				apiKey: `Bearer ${appState.verifyToken}`,
				basePath: CONFERENCE_CONTROL_API_URL
			}));
			await client.participant.dial({
				roomId: appState.conferenceId,
				ciptexRoomDial: { phoneNumber, pin: appState.participantPin }
			});
		}
	};

	const muteTelephony = async (userIdentity: string, muteTelephonyParam: boolean): Promise<void> => {
		await conferenceControlClient?.participant.updateSubscriptions({
			roomId: appState.conferenceId,
			ciptexSubscriptionUpdate: {
				userIdentity: userIdentity,
				mute: muteTelephonyParam
			}
		});
	};

	return <ConferenceControlContext.Provider value={{ muteTelephony, dialOut, removeSpeaker, switchToViewer, speakerInvite, endEvent, viewerUpdate, raiseHand, toggleStream, toggleOpen, startRoom, joinStreamAsSpeaker, joinStreamAsViewer, updatePriority, setPlaybackTimer, playbackTimer, setIsPlaybackBotPlaying, isPlaybackBotPlaying }}>{children}</ConferenceControlContext.Provider>;
}
